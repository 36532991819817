import React from "react";
import { Box, Button, Container, Typography } from "@mui/material";
import RestaurantMenu from "@mui/icons-material/RestaurantMenu";
import "./homepage.css";

const HomePage = () => {
  return (
    <Container
      //className="homepage"
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        pb: 2,

        width: "100%",
        borderBottom: "1px dashed orange",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            md: "row",
          },
          flex: 1,
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            display: { xs: "none", md: "flex" },
            flex: 1,
            width: { xs: "100%", md: "50%", padding: "5rem" },
          }}
        >
          <img
            src={require(`../images/jollof1.png`)}
            alt="preview-menu"
            style={{ width: "75%" }}
          />
        </Box>
        <Box
          sx={{
            display: { md: "none", xs: "flex" },

            flex: 1,
            width: { xs: "50%", md: "50%", padding: "1rem" },
          }}
        >
          <img
            src={require(`../images/jollof1.png`)}
            alt="preview-menu"
            style={{ width: "100%" }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flex: 1,
            width: { xs: "100%", md: "50%" },
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <Typography
            variant="h2"
            color="orange"
            fontFamily="Delius Swash Caps"
            sx={{
              fontSize: {
                md: "4rem",
                xs: "3rem",
              },
            }}
          >
            <span style={{}}>K</span>ess <span style={{}}>K</span>itchen
          </Typography>
          <Typography variant="h5" color="orange">
            African Cuisine
          </Typography>
          <Typography
            variant="h5"
            sx={{
              color: "#aaa",
              textAlign: "left",
              mt: 3,
              mb: 2,
              fontSize: {
                md: "1.2rem",
                xs: "1rem",
                lineHeight: "2rem",
              },
              whiteSpace: "preserve",
            }}
          >
            <p>Welcome to Kess Kitchen African Cuisine</p>
            <p>
              At Kess Kitchen, we bring the authentic flavors of Africa right to
              your table. Specializing in traditional recipes with a modern
              touch, we’re passionate about showcasing the richness of African
              culture through delicious food.
            </p>

            <p>
              Our Offerings: {`\n`}
              From spicy Jollof Rice to savory Suya and sweet beef pies, our
              menu captures the diverse flavors of Africa. We also offer
              catering and personal chef services, perfect for events of any
              size.
            </p>
            <p>
              Why Choose Us? {`\n`}
              Kess Kitchen is committed to quality, authenticity, and a love for
              African cuisine. Every dish is crafted with fresh ingredients and
              served with care.
            </p>
            <p>
              Get in Touch {`\n`}
              Ready to explore African flavors? Contact us for orders, catering,
              or more information. Follow us on social media for updates and new
              dishes!
              <p>Kess Kitchen – Where Tradition Meets Taste.</p>
            </p>
          </Typography>
          <Box>
            <Button
              variant="contained"
              sx={{ width: "200px" }}
              href="/menu"
              startIcon={<RestaurantMenu />}
            >
              Place an Order
            </Button>
          </Box>
          <Typography variant="subtitle2" sx={{ color: "orange", mt: 2 }}>
            We're located at:{" "}
            <a
              href="https://maps.app.goo.gl/gMQy3uAzqxnAeG688"
              target="_blank"
              rel="noreferrer"
              style={{ color: "#8e5c00" }}
            >
              Jacksonville Shopping Mall, 4663 River City Dr, Jacksonville, FL
              32246
            </a>
          </Typography>
        </Box>
      </Box>
      {/* <Typography
        variant="h2"
        gutterBottom
        sx={{
          mt: 1,
          textAlign: "center",
          fontSize: {
            md: "3rem",
            xs: "2rem",
          },
        }}
      >
        Welcome to Kess Kitchen
      </Typography>

      <Typography
        variant="h5"
        sx={{
          textAlign: "center",
          mb: 1,
          mt: 3,
          fontSize: {
            md: "2rem",
            xs: "1.3rem",
          },
        }}
      >
        Celebrating Nigerian Cuisine with Every Event
      </Typography>
      <Typography
        variant="h5"
        sx={{
          textAlign: "left",
          mt: 3,
          mb: 2,
          fontSize: {
            md: "1.2rem",
            xs: "1rem",
            lineHeight: "1.5rem",
          },
        }}
      >
        At Kess Kitchen, we are dedicated to bringing the vibrant and diverse
        flavors of Nigerian cuisine to your special occasions. Whether it's a
        wedding, birthday, corporate event, or any celebration, our expertly
        crafted dishes are sure to delight your guests. Servicing the Florida
        area (Jacksonville, Orlando, Tampa, Miami)
      </Typography>
      <Button
        size="large"
        variant="contained"
        sx={{
          width: {
            xs: "100%",
            md: "50%",
          },
        }}
        href="/menu"
      >
        Place Order
      </Button> */}
    </Container>
  );
};

export default HomePage;
