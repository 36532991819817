// Header.tsx
import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  LocalDining,
  LocalDiningOutlined,
  LocalDiningRounded,
  RestartAltOutlined,
  RestaurantMenuOutlined,
  ShoppingCart,
} from "@mui/icons-material";
import { useCart } from "./CartContext";

const Header: React.FC = () => {
  const theme = useTheme();
  const nav = useNavigate();
  const { cart } = useCart();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const menuItems = [
    //{ text: "Gallery", path: "/gallery" },
    { text: "Menu", path: "/menu" },
    { text: "Contact", path: "/contact" },
  ];

  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button key="home" component={RouterLink} to={"/"}>
          <ListItemText primary="Home" />
        </ListItem>
        {menuItems.map((item) => (
          <ListItem
            button
            key={item.text}
            component={RouterLink}
            to={item.path}
          >
            <ListItemText primary={item.text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const Cart = () => (
    <Box
      visibility={cart.length === 0 ? "hidden" : "visible"}
      display={"flex"}
      sx={{
        background: "white",
        p: 1,
        borderRadius: 3,
        cursor: "pointer",
      }}
      onClick={() => nav("cart")}
    >
      <ShoppingCart color="success" />
      <Typography color="green" sx={{ ml: 1, fontWeight: "bold" }}>
        {cart.length}
      </Typography>
    </Box>
  );
  return (
    <>
      <AppBar
        position="sticky"
        sx={{ backgroundColor: "black", boxShadow: "none" }}
      >
        <Toolbar>
          {/* <Box sx={{ flex: 1 }}></Box> */}
          {isMobile && (
            <>
              <IconButton
                color="inherit"
                edge="start"
                onClick={toggleDrawer(true)}
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                anchor="right"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
              >
                {drawer}
              </Drawer>
            </>
          )}

          {!isMobile && (
            <Box sx={{ display: "flex", visibility: "hidden" }}>
              <Cart />

              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  color="inherit"
                  component={RouterLink}
                  to={item.path}
                >
                  {item.text}
                </Button>
              ))}
            </Box>
          )}

          <Box
            sx={{
              flex: 1,
              alignItems: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            {/* <div>
                  <LocalDiningOutlined sx={{ mr: 2 }} />
                </div> */}
            <Typography
              variant="h6"
              sx={{
                //   flexGrow: 1,
                textAlign: "center",
                cursor: "pointer",
                width: "100%",
                textDecoration: "underline",
                textDecorationColor: "orange",
                textUnderlineOffset: "0.5rem",
              }}
              onClick={() => nav("/")}
              fontFamily="Delius Swash Caps"
            >
              Kess Kitchen
            </Typography>
          </Box>
          {!isMobile && (
            <Box sx={{ display: "flex" }}>
              <Cart />

              {menuItems.map((item) => (
                <Button
                  key={item.text}
                  color="inherit"
                  component={RouterLink}
                  to={item.path}
                >
                  {item.text}
                </Button>
              ))}
            </Box>
          )}
          {isMobile && <Cart />}
        </Toolbar>
      </AppBar>
    </>
  );
};

export default Header;
